import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: '',
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listBalai: [],
      listStatker: [],
      list_usersTypes: [],
      list_userGroup: [],
      isBalai:false,
      isSatker:false,
      listGiat: [],
      listSatker:[]

    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.getTable()

    this.G_GetRef('giat').then(data => {
      this.listGiat = data;
    })
    this.G_GetRef('satker','?typ=direktorat').then(data => {
      this.listSatker = data;
    })

   
  },
  components: {
    myTable
  },
  methods: {
    getTable() {
      this.loadingTb = true
      this.G_GetAny('direktorat-giat').then(res => {
     
        this.loadingTb = false
        this.tbData = res.data.content

        console.log(this.tbData);
      })
    },
  
    edit(kdsatker,kdgiat) {
      console.log(id);
      this.G_GetAny(`direktorat-giat/${kdsatker}/${kdgiat}`).then(res => {
        this.getData = res.data
        // this.setId = res.data.userId

        this.$refs.modal.open("Edit Data Kegiatan per Direktorat", 600)

        this.$nextTick(() => {

        });
      })
    },
    add() {
      if (this.$refs.form) {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
      this.setId = null
      this.getData = []
      this.$refs.modal.open("Tambah Data Kegiatan per Direktorat", 600)

    },
    store() {
      let myForm = document.getElementById('formData');
      let formData = new FormData(myForm);
      formData.append('kdsatker',this.$refs.kdsatker.getValue())
      formData.append('kdgiat',this.$refs.kdgiat.getValue())

      if (this.setId) {
        formData.append('id',this.setId)
      }

  

      if (this.$refs.form.validate()) {
          this.$refs.modal.loading()

          if (this.setId) {
            this.G_PutAny('direktorat-giat', formData).then(res => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success('Berhasil Mengubah Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning(res.data.message,'Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }else{
            this.G_PostAny('direktorat-giat', formData).then(res => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success('Berhasil Menyimpan Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning(res.data.message);
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }

         
      }
      else {
        this.$snotify.info('Lengkapi Data Terlebih Dahulu');
      }
    },
    async hapus(kdsatker,kdgiat) {
      let judul = "Hapus Data"
      let subJudul = "Yakin Akan Hapus Data Ini?"

      if (await this.$refs.confirm.open(judul,subJudul) ) {
         
          this.$refs.confirm.loading()
          this.G_DeleteAny(`direktorat-giat/${kdsatker}/${kdgiat}`).then(res => {
            
              if (res) {
                  this.$refs.confirm.close()
                  this.$refs.modal.close()
                  this.getTable();
                  this.$snotify.success(res.message);
                  // this.$refs.notif.success(res.message,`Berhasil ${judul}`);
              }else{
                this.$snotify.warning(res.message);

                  // this.$refs.notif.warning(res.message,'Terjadi Kesalahan');
              }
          })
          .finally(() => {
              this.$refs.confirm.loadingEnd()
          }); 
      }

    },
  }
}